import { createThunk } from '../../store/actions/utils'
import PickemApi from './PickemApi'

export const getPickemLeagues = createThunk(
  'pickem/getLeagues',
  PickemApi.fetchLeagues
)

export const updatePickemLeague = createThunk(
  'pickem/updateLeague',
  PickemApi.updateLeague
)

export const updatePickemTournament = createThunk(
  'pickem/updateTournament',
  PickemApi.updateTournament
)

// Pick Block Actions
export const getPickemUnscheduledItems = createThunk(
  'pickem/getUnscheduledItems',
  PickemApi.fetchUnscheduled
)

export const getPickemPickBlocks = createThunk(
  'pickem/getPickBlocks',
  PickemApi.fetchPickBlocks
)

export const insertPickemPickBlock = createThunk(
  'pickem/insertPickBlock',
  PickemApi.insertPickBlock
)

export const updatePickemPickBlock = createThunk(
  'pickem/updatePickBlock',
  PickemApi.updatePickBlock
)

export const deletePickemPickBlock = createThunk(
  'pickem/deletePickBlock',
  PickemApi.deletePickBlock
)

export const forcePickemPickBlockState = createThunk(
  'pickem/forcePickBlockState',
  PickemApi.forcePickBlockState
)

// Celebrity Actions
export const getPickemCelebritiesForTournament = createThunk(
  'pickem/getCelebritiesForTournament',
  PickemApi.fetchCelebritiesForTournament
)

// Crystal Ball Actions
export const certifyPickemPrompts = createThunk(
  'pickem/certifyPrompts',
  PickemApi.certifyPrompts
)

export const deletePickemChoiceTiers = createThunk(
  'pickem/deleteChoiceTiers',
  PickemApi.deleteChoiceTiers
)

export const deletePickemChoices = createThunk(
  'pickem/deleteChoices',
  PickemApi.deleteChoices
)

export const deletePickemPrompt = createThunk(
  'pickem/deletePrompt',
  PickemApi.deletePrompt
)

export const getPickemAnswers = createThunk(
  'pickem/getAnswers',
  PickemApi.getAnswers
)

export const getPickemChoiceTiers = createThunk(
  'pickem/getChoiceTiers',
  PickemApi.getChoiceTiers
)

export const getPickemQuestions = createThunk(
  'pickem/getQuestions',
  PickemApi.getQuestions
)

export const getPickemPrompts = createThunk(
  'pickem/getPrompts',
  PickemApi.getPrompts
)

export const insertPickemAnswers = createThunk(
  'pickem/insertAnswers',
  PickemApi.insertAnswers
)

export const insertPickemChoices = createThunk(
  'pickem/insertChoices',
  PickemApi.insertChoices
)

export const insertPickemChoiceTiers = createThunk(
  'pickem/insertChoiceTiers',
  PickemApi.insertChoiceTiers
)

export const insertPickemPrompts = createThunk(
  'pickem/insertPrompts',
  PickemApi.insertPrompts
)

export const insertPickemQuestions = createThunk(
  'pickem/insertQuestions',
  PickemApi.insertQuestions
)

export const updatePickemAnswerState = createThunk(
  'pickem/updateAnswerState',
  PickemApi.updateAnswerState
)

export const updatePickemQuestionState = createThunk(
  'pickem/updateQuestionState',
  PickemApi.updateQuestionState
)

export const updatePickemChoiceScore = createThunk(
  'pickem/updateChoiceScore',
  PickemApi.updateChoiceScore
)

// Localization Actions
export const getQuestionLocalizations = createThunk(
  'pickem/getQuestionLocalizations',
  PickemApi.getQuestionLocalizations
)
export const getAnswerLocalizations = createThunk(
  'pickem/getAnswerLocalizations',
  PickemApi.getAnswerLocalizations
)

export const insertPickemLocalizations = createThunk(
  'pickem/insertLocalizations',
  PickemApi.insertLocalizations
)

export const updatePickemLocalizations = createThunk(
  'pickem/updateLocalizations',
  PickemApi.updateLocalizations
)

export const deletePickemLocalization = createThunk(
  'pickem/deleteLocalization',
  PickemApi.deleteLocalization
)
// Reward Actions
export const getPickemRewardCriteria = createThunk(
  'pickem/getRewardCriteria',
  PickemApi.fetchRewardCriteriaForTournament
)

export const insertPickemRewardCriteria = createThunk(
  'pickem/insertRewardCriteria',
  PickemApi.insertRewardCriteria
)

export const updatePickemRewardCriteria = createThunk(
  'pickem/updateRewardCriteria',
  PickemApi.updateRewardCriteria
)

export const deletePickemRewardCriteria = createThunk(
  'pickem/deleteRewardCriteria',
  PickemApi.deleteRewardCriteria
)

// Scoring Configuration Actions

export const getScoringConfigurations = createThunk(
  'pickem/getScoringConfigurations',
  PickemApi.getScoringConfigurations
)

export const getScoringConfiguration = createThunk(
  'pickem/getScoringConfiguration',
  PickemApi.getScoringConfiguration
)

export const getScoringConfigurationBySection = createThunk(
  'pickem/getScoringConfigurationBySection',
  PickemApi.getScoringConfigurationBySection
)

export const createScoringConfiguration = createThunk(
  'pickem/createScoringConfiguration',
  PickemApi.createScoringConfiguration
)

export const updateSectionScoringConfiguration = createThunk(
  'pickem/updateSectionScoringConfiguration',
  PickemApi.updateSectionScoringConfiguration
)

export const deleteSectionScoringConfiguration = createThunk(
  'pickem/deleteSectionScoringConfiguration',
  PickemApi.deleteSectionScoringConfiguration
)
