import { AwsS3 } from '..'
import { Config, fetch, urlBuilder } from '../../commons'
import { BannerConfigurationDTO } from '../../containers/Banners/Banners.type'
import { createThunk } from '../../store/actions/utils'
import { FunctionalityType } from '../AwsS3/AwsS3.type'
import { fetchDDVersions } from '../DataDragonApi/DataDragonApi'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'
import {
  League,
  LeaguesResponse,
  PermissionsResponse
} from './BannersService.type'

export const BannersApiEnvSessionStorageKey = 'bannersApiEnv'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.Banners,
  Config.getEnv(BannersApiEnvSessionStorageKey)
)
const konfiguratorBaseUrl = `${apiBaseUrl}/konfigurator/v1/`

const bannersUrl = urlBuilder({
  local: konfiguratorBaseUrl,
  dev: konfiguratorBaseUrl,
  test: konfiguratorBaseUrl,
  prod: konfiguratorBaseUrl
})

// Rather than fetching the patch numbers from the DataDragon API which doesn't always
// return the latest patch numbers, we hardcode the patch numbers here since they follow
// a regular pre-defined pattern.
const getPatchList = (): string[] => {
  const minMinorPatch = 1
  const maxMinorPatch = 24
  const patchMajors = ['15', '14', '13']
  const patchList: string[] = []

  patchMajors.forEach((major, majorIndex) => {
    if (majorIndex === 0) {
      for (let i = minMinorPatch; i <= maxMinorPatch; i++) {
        patchList.push(`${major}.${i}`)
      }
    }
    else {
      for (let i = maxMinorPatch; i >= minMinorPatch; i--) {
        patchList.push(`${major}.${i}`)
      }
    }
  })

  return patchList
}

class BannersService {
  static async getOptions (method = 'POST'): Promise<RequestInit> {
    return {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }

  async fetchPatches (): Promise<string[]> {
    return getPatchList()
  }

  async fetchPermissions (): Promise<PermissionsResponse> {
    const permissions: PermissionsResponse = await fetch(
      bannersUrl('permissions'),
      await BannersService.getOptions('GET')
    )
    return permissions
  }

  async fetchLeagues (): Promise<LeaguesResponse> {
    const json: LeaguesResponse = await fetch(
      bannersUrl('leagues'),
      await BannersService.getOptions('GET')
    )
    return json
  }

  async postLeague (config: League): Promise<void> {
    const options = await BannersService.getOptions('POST')

    return fetch(bannersUrl('leagues/'), {
      ...options,
      body: JSON.stringify(config)
    })
  }

  async putConfiguration (league: League, patchIndex: number): Promise<void> {
    const options = await BannersService.getOptions('PUT')

    return fetch(bannersUrl(`leagues/${league.id}`), {
      ...options,
      body: JSON.stringify(league.patches[patchIndex])
    })
  }

  async fetchS3Config (configUrl: string): Promise<BannerConfigurationDTO> {
    const key = new URL(configUrl).pathname.substring(1)
    const presiginedUrl = await AwsS3.getPresignedUrlForAsset(
      key,
      FunctionalityType.Banner
    )
    const json: BannerConfigurationDTO = await fetch(
      presiginedUrl,
      await BannersService.getOptions('GET'),
      true
    )
    return json
  }
}

const api = new BannersService()
export default api

export const fetchPatches = createThunk('banners/getPatches', api.fetchPatches)

export const fetchBannerLeagues = createThunk(
  'banners/getLeagues',
  api.fetchLeagues
)

export const createBannerLeague = createThunk(
  'banners/createLeague',
  api.postLeague
)

export const putConfiguration = createThunk(
  'banners/putConfiguration',
  api.putConfiguration
)
