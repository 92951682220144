import {
  Tournament,
  Edge,
  LineupEntry,
  Lineup,
  Section,
  TournamentStage,
  DecisionPointConfig,
  MatchConfig,
  TournamentStatus,
  TournamentSchemaType,
  SectionType,
  MatchConfigStrategy,
  TeamRecord,
  ParticipantRecord,
  Rank,
  MatchColumn,
  MatchCell,
  MatchView,
  SectionColumn,
  SectionCell
} from '@riotgames/api-types/elds/Tournaments.type'
import {
  BestOfConfig,
  PlayAllConfig
} from '@riotgames/api-types/elds/Matches.type'
import { createArray, generateStructuralId } from '../'
import {
  LocalizedStringsWithSlug,
  PublicationStatus,
  Sport
} from '@riotgames/api-types/elds/Common.type'

// TODO: Figure out why putting this in Util.ts breaks the build
/**
 * Creates a factory function for building and updating arbitrary objects. When
 * first invoked, takes a default object that all future objects will be built
 * from, and returns a function for creating and updating objects of that type.
 * @param defaults the default object template
 */
export const createFactory
  = <T>(defaults: DeepPartial<T>) =>
    (...partials: Partial<T>[]): T =>
      Object.assign({}, defaults, ...partials)

export const createLineup = createFactory<Lineup>({
  outcomeSource: undefined,
  entries: []
})

export const createParticipantRecord = createFactory<ParticipantRecord>({
  wins: 0,
  losses: 0,
  ties: 0
})

export const createTeamRecord = createFactory<TeamRecord>({
  teamId: undefined,
  record: createParticipantRecord()
})

export const createRank = createFactory<Rank>({
  ordinal: undefined,
  tiedTeams: []
})

export const createLocalizedName = createFactory<LocalizedStringsWithSlug>({
  slug: '',
  localizations: {}
})

export const createTournament = createFactory<DeepPartial<Tournament>>({
  id: undefined,
  leagueId: '',
  name: createLocalizedName(),
  publicationStatus: PublicationStatus.Draft,
  schemaType: TournamentSchemaType.GraphStructured,
  sport: Sport.Lol,
  status: TournamentStatus.Draft,
  seeding: createLineup(),
  standing: createLineup(),
  startTime: '',
  endTime: '',
  streamGroupId: undefined,
  stages: [],
  edges: [],
  seasonId: undefined,
  splitId: undefined,
  points: { entries: [] },
  awardedCircuitPoints: [],
  timeZone: 'Etc/UTC'
})

export const findEdge
  = (type: 'source' | 'target') =>
    (slot: number) =>
      (id: string) =>
        (edge: Edge): boolean =>
          edge[`${type}Slot`] === slot && edge[`${type}NodeId`] === id

export const findSourceEdge = findEdge('source')
export const findTargetEdge = findEdge('target')

export const findWinnerOf = findTargetEdge(1)
export const findLoserOf = findTargetEdge(2)

export const createLineupEntry = createFactory<DeepPartial<LineupEntry>>({
  index: 1,
  teamId: undefined,
  description: undefined,
  // Tournament standings fails if an invalid standing (ie. out of range [1, (tournament team size)]) is set
  placement: 1
})

export const resizeLineup = (
  lineup: Lineup,
  newSize: number
): DeepPartial<Lineup> => ({
  outcomeSource: undefined,
  entries: createArray(newSize).map(
    (index) =>
      ({
        index: lineup.entries[index]?.index || index + 1,
        teamId: lineup.entries[index]?.teamId,
        description: lineup.entries[index]?.description || '',
        // Tournament creation fails if an invalid standing (ie. out of range [1, (tournament team size)]) is set.
        // If a placement is already set, then all or none placements must be set. Since this will get reordered
        // once standings are finalized, setting this to the index + 1 will keep it in an acceptable and valid range
        placement: lineup.entries[index]?.placement || index + 1
      }) as LineupEntry
  )
})

// The nodeType fields are deprecated, so they are undefined here to indicate
// that we are not using them.  They must be included since they're unfortunately
// still part of the TS type definition
export const createEdge = createFactory<Edge>({
  sourceSlot: null,
  sourceNodeId: null,
  sourceNodeType: undefined,
  targetSlot: null,
  targetNodeId: null,
  targetNodeType: undefined
})

export const createSection = createFactory<DeepPartial<Section>>({
  id: '',
  structuralId: '',
  publicationStatus: PublicationStatus.Draft,
  name: createLocalizedName(),
  type: SectionType.Bracket,
  seeding: createLineup(),
  standing: createLineup(),
  groupConfig: undefined,
  teamRecords: {},
  ranks: [],
  matches: [],
  decisionPoints: [],
  edges: [],
  columns: []
})

export const createStage = createFactory<TournamentStage>({
  id: '',
  structuralId: '',
  publicationStatus: PublicationStatus.Draft,
  name: createLocalizedName(),
  seeding: createLineup(),
  standing: createLineup(),
  sections: [],
  edges: [],
  columns: []
})

export const createDecisionPoint = createFactory<DecisionPointConfig>({
  id: '',
  structuralId: '',
  description: '',
  seeding: createLineup(),
  standing: createLineup()
})

export const createBestOf = createFactory<BestOfConfig>({
  count: 1
})

export const createPlayAll = createFactory<PlayAllConfig>({
  count: 1
})

export const createMatch = createFactory<DeepPartial<MatchConfig>>({
  id: '',
  structuralId: '',
  description: '',
  strategy: MatchConfigStrategy.BestOf,
  bestOfConfig: createBestOf(),
  playAllConfig: undefined
})

export const createMatchView = createFactory<NullableProperties<MatchView>>({
  matchId: null,
  structuralId: null,
  teamOne: '',
  teamTwo: ''
})

export const createSectionCell = createFactory<NullableProperties<SectionCell>>(
  {
    name: { slug: '', localizations: {} },
    contents: []
  }
)

export const areEdgesLinked = (source: Edge, target: Edge): boolean =>
  source.targetNodeId === target.sourceNodeId
  && source.targetNodeType === target.sourceNodeType
  && source.targetSlot === target.sourceSlot

export const sameTargetNode = (
  edge: Partial<Edge>,
  otherEdge: Partial<Edge>
): boolean =>
  edge.targetSlot === otherEdge.targetSlot
  && edge.targetNodeId === otherEdge.targetNodeId
  && edge.targetNodeType === otherEdge.targetNodeType

export const sameSourceNode = (
  edge: Partial<Edge>,
  otherEdge: Partial<Edge>
): boolean =>
  edge.sourceSlot === otherEdge.sourceSlot
  && edge.sourceNodeId === otherEdge.sourceNodeId
  && edge.sourceNodeType === otherEdge.sourceNodeType

export const addIdMappingIfMissing = (
  id: string,
  mapping: Record<string, string>,
  prefix: string
) => {
  if (id != null && id !== '' && mapping[id] == null) {
    mapping[id] = generateStructuralId(prefix)
  }
}

export const removeTeamIdFromLineup = (
  entries: LineupEntry[]
): Partial<LineupEntry[]> =>
  entries.map(
    (entry: LineupEntry) =>
      ({
        index: entry.index,
        description: entry.description,
        placement: entry.placement
      }) as LineupEntry
  )

export const remapEdges = (
  edges: Edge[],
  mapping: Record<string, string>
): Edge[] =>
  edges.map((edge: Edge) => {
    edge.sourceNodeId = mapping[edge.sourceNodeId as string]
    edge.targetNodeId = mapping[edge.targetNodeId as string]
    return edge
  })

export type SectionConfig = MatchConfig | DecisionPointConfig

export const remapSectionConfigs = (
  configs: SectionConfig[],
  mapping: Record<string, string>,
  prefix: string
): SectionConfig[] =>
  configs.map((config: SectionConfig) => {
    addIdMappingIfMissing(config.structuralId, mapping, prefix)
    config.id = ''
    config.structuralId = mapping[config.structuralId]
    return config
  })

export const remapMatchColumns = (
  columns: MatchColumn[],
  mapping: Record<string, string>
): MatchColumn[] =>
  columns.map((column: MatchColumn) => {
    column.cells = column.cells.map((cell: MatchCell) => {
      cell.contents = cell.contents.map(
        (content: MatchView) =>
          ({ structuralId: mapping[content.structuralId] }) as MatchView
      )
      return cell
    })
    return column
  })

export const remapSectionColumns = (
  columns: SectionColumn[],
  mapping: Record<string, string>
): SectionColumn[] =>
  columns.map((column: SectionColumn) => {
    column.cells = column.cells.map((cell: SectionCell) => {
      cell.contents = cell.contents.map((content: string) => mapping[content])
      return cell
    })
    return column
  })

export const extractMatchesPerSection = (
  tournament: Tournament
): Map<string, MatchConfig[]> =>
  tournament.stages.reduce((acc, stage) => {
    stage.sections.forEach((section) => {
      const key = `${stage.name.slug}-${section.name.slug}`
      acc.set(key, section.matches || [])
    })
    return acc
  }, new Map<string, MatchConfig[]>())
