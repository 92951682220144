import { useSelector } from 'react-redux'
import { LocalizedStringsWithSlug } from '@riotgames/api-types/elds/Common.type'
import { debounce } from '../../commons'
import { Section, ValidatedInput } from '..'
import { getLocales } from '../../store/reducers'
import { localizedNameMaxLength } from '../../commons/validators/validators'
import { EditLocalizationParameters } from './LocalizationPicker.type'
import styles from './LocalizationPicker.scss'

type Props = {
  currentLocalizations: LocalizedStringsWithSlug
  locales?: string[]
  onUpdate: (params: EditLocalizationParameters) => void
}

const LocalizationPicker = (props: Props) => {
  const renderLocalization = (
    locale: string,
    localeName: string
  ): JSX.Element => {
    const { onUpdate } = props

    const onInput = debounce(
      ({ target }: DOMEventTarget) =>
        onUpdate({
          locale: locale,
          localeName: (target as HTMLInputElement).value
        }),
      500
    )

    return (
      <li className={ styles.localization } key={ locale }>
        <ValidatedInput
          dir={ locale === 'ar-AE' ? 'rtl' : 'ltr' }
          title={ locale }
          value={ localeName || '' }
          onInput={ onInput }
          validator={ localizedNameMaxLength(localeName) }
          validationStyles={ { wrapper: styles.slugEditor } }
        />
      </li>
    )
  }

  const renderLocalizations = (): JSX.Element => {
    const locales = useSelector(getLocales)
    const { currentLocalizations } = props

    return (
      <ul className={ styles.localizationsList }>
        { locales.map((locale) =>
          renderLocalization(
            locale,
            currentLocalizations?.localizations[locale]
          )
        ) }
      </ul>
    )
  }

  return <Section title="Localizations">{ renderLocalizations() }</Section>
}

export default LocalizationPicker
