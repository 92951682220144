import { RecordStatus } from '@riotgames/api-types/elds/Common.type'
import { Split } from '@riotgames/api-types/elds/Seasons.type'
import { redirect } from 'react-router-dom'
import { Dispatch } from 'redux'
import { displayMessage } from '../../components/Message/Message.slice'
import { MessageType } from '../../components/Message/Message.type'
import {
  createNewSeason,
  editSeason,
  archiveSeason,
  unarchiveSeason
} from '../../services/Elds/Elds.actions'
import { getSeasonById, getSport, getWIPSeasonById } from '../../store/reducers'
import { RootState } from '../../store/Store.type'

export const saveNewSeason
  = (seasonId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const season = getWIPSeasonById(getState(), seasonId)

    if (season?.sport === null) {
      season.sport = getSport(getState())
    }

    createNewSeason(season)(dispatch)
      .then(() => {
        displayMessage(
          'Created Season Successfully!',
          MessageType.Success
        )(dispatch)
      })
      .then(() => window.route('/season/list/current'))
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const updateSeason
  = (seasonId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const season = getWIPSeasonById(getState(), seasonId)

    editSeason(season)(dispatch)
      .then(() => {
        displayMessage(
          'Updated Season Successfully!',
          MessageType.Success
        )(dispatch)
      })
      .then(() => window.route('/season/list/current'))
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const archiveExistingSeason
  = (seasonId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const baseSeason = getSeasonById(getState(), seasonId)
    const season = { ...baseSeason, status: RecordStatus.Archived }

    archiveSeason(season)(dispatch)
      .then(() =>
        displayMessage(
          'Archived Season Successfully!',
          MessageType.Success
        )(dispatch)
      )
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const unarchiveExistingSeason
  = (seasonId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const baseSeason = getSeasonById(getState(), seasonId)
    const season = { ...baseSeason, status: RecordStatus.Active }

    unarchiveSeason(season)(dispatch)
      .then(() =>
        displayMessage(
          'Unarchived Season Successfully!',
          MessageType.Success
        )(dispatch)
      )
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }
