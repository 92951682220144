/* eslint-disable max-lines */
import { AnyAction, combineReducers, Reducer } from 'redux'
import {
  insertPickemPickBlock as insertPickBlock,
  deletePickemPickBlock as deletePickBlock,
  forcePickemPickBlockState as forcePickBlockState,
  getPickemCelebritiesForTournament as getCelebritiesForTournament,
  getPickemLeagues as getLeagues,
  getPickemUnscheduledItems as getUnscheduledItems,
  getPickemPickBlocks as getPickBlocks,
  updatePickemPickBlock as updatePickBlock,
  getPickemRewardCriteria as getRewardCriteria,
  insertPickemRewardCriteria as insertRewardCriteria,
  deletePickemRewardCriteria as deleteRewardCriteria,
  updatePickemRewardCriteria as updateRewardCriteria,
  getPickemQuestions,
  getPickemAnswers,
  getQuestionLocalizations,
  insertPickemAnswers,
  insertPickemQuestions,
  getPickemPrompts as getPrompts,
  insertPickemPrompts as insertPrompts,
  insertPickemChoices as insertChoices,
  insertPickemLocalizations,
  updatePickemLocalizations,
  deletePickemLocalization,
  getAnswerLocalizations,
  getPickemChoiceTiers as getChoiceTiers,
  insertPickemChoiceTiers as insertChoiceTiers,
  updatePickemAnswerState,
  updatePickemQuestionState as updateQuestionState,
  deletePickemChoiceTiers as deleteChoiceTiers,
  deletePickemChoices as deleteChoices,
  deletePickemPrompt as deletePrompt,
  updatePickemChoiceScore as updateChoiceScore,
  certifyPickemPrompts as certifyPrompts,
  getScoringConfiguration,
  getScoringConfigurations,
  getScoringConfigurationBySection,
  createScoringConfiguration,
  updatePickemTournament,
  updatePickemLeague
} from './PickemApi.actions'
import {
  CelebrityDTO,
  LeagueDTO,
  PickBlockDTO,
  UnscheduledItemsDTO,
  StateShape,
  RewardCriteriaDTO,
  QuestionDTO,
  AnswerDTO,
  PromptDTO,
  ChoiceDTO,
  LocalizationDTO,
  ChoiceTierDTO,
  TournamentDTO,
  ScoringConfigurationDTO,
  AdminLeagueDTO
} from './PickemApi.type'
import { getSafe, keyBy, keyById, omit } from '../../commons'
import { AnyPayloadAction, PayloadAction } from '../../store/Store.type'
import { createSlice, isLoading } from '../../store/actions/utils'
import { getMatchesById } from '../Elds/Elds.actions'

const isLoadingReducer = combineReducers({
  isMatchesLoading: isLoading(getMatchesById),
  isLeaguesLoading: isLoading(getLeagues),
  isUnscheduledItemsLoading: isLoading(getUnscheduledItems),
  isQuestionLocalizationsLoading: isLoading(getQuestionLocalizations),
  isPickBlockOpLoading:
    isLoading(getPickBlocks)
    || isLoading(insertPickBlock)
    || isLoading(updatePickBlock)
    || isLoading(deletePickBlock)
})

const byMetaEldsLeagueIdReducer = (
  state: Record<string, LeagueDTO>,
  { payload, meta }: PayloadAction<LeagueDTO, string>
): Record<string, LeagueDTO> => {
  if (payload.published) {
    return { ...state, [meta]: payload }
  }
  else {
    return Object.values(state)
      .filter((league) => league.eldsLeagueId !== payload.eldsLeagueId)
      .reduce(
        (leagueMap, league) => {
          leagueMap[league.eldsLeagueId] = league
          return leagueMap
        },
        {} as Record<string, LeagueDTO>
      )
  }
}

// These utility functions `byMetaXYReducer` are separated because the underlying payloads don't have a shared generic interface
const byMetaEldsTournamentIdCelebritiesReducer = (
  state: Record<string, CelebrityDTO[]>,
  { payload, meta }: PayloadAction<CelebrityDTO[], string>
): Record<string, CelebrityDTO[]> => ({
  ...state,
  [meta]: payload
})

const byMetaIdLocalizationReducer = (
  state: Record<string, LocalizationDTO[]>,
  { payload, meta }: PayloadAction<LocalizationDTO[], string>
): Record<string, LocalizationDTO[]> => ({
  ...state,
  [meta]: payload
})

const appendByIdLocalizationReducer = (
  state: Record<string, LocalizationDTO[]>,
  { payload, meta }: PayloadAction<LocalizationDTO[], any>
): Record<string, LocalizationDTO[]> => {
  const updatedState = [...state[meta[0]], ...meta[1]]
  return {
    ...state,
    [meta[0]]: updatedState
  }
}

const replaceByIdLocalizationReducer = (
  state: Record<string, LocalizationDTO[]>,
  { payload, meta }: PayloadAction<LocalizationDTO[], any>
): Record<string, LocalizationDTO[]> => {
  const updatedState = Object.values(state[meta[0]]).map((item) =>
    item.locale === meta[1][0].locale ? { ...item, ...meta[1][0] } : item
  )
  return {
    ...state,
    [meta[0]]: updatedState
  }
}

const byMetaEldsTournamentIdPickBlocksReducer = (
  state: Record<string, PickBlockDTO[]>,
  { payload, meta }: PayloadAction<PickBlockDTO[], string>
): Record<string, PickBlockDTO[]> => ({
  ...state,
  [meta]: payload
})

const byMetaEldsTournamentIdUnscheduledItemsReducer = (
  state: Record<string, UnscheduledItemsDTO>,
  { payload, meta }: PayloadAction<UnscheduledItemsDTO, string>
): Record<string, UnscheduledItemsDTO> => ({
  ...state,
  [meta]: payload
})

const byMetaEldsTournamentIdRewardCriteriaReducer = (
  state: Record<string, RewardCriteriaDTO[]>,
  { payload, meta }: PayloadAction<RewardCriteriaDTO[], string>
): Record<string, RewardCriteriaDTO[]> => ({
  ...state,
  [meta]: payload
})

const byMetaEldsTournamentIdPromptReducer = (
  state: Record<string, PromptDTO[]>,
  { payload, meta }: PayloadAction<PromptDTO[], [string, PromptDTO]>
): Record<string, PromptDTO[]> => {
  const eldsTournamentId = meta[0]
  const updatedPrompts = state[eldsTournamentId]
    ? [...state[eldsTournamentId], ...payload]
    : payload

  return {
    ...state,
    [eldsTournamentId]: updatedPrompts
  }
}

const byChoicePromptReducer = (
  state: Record<string, PromptDTO[]>,
  { payload, meta }: PayloadAction<ChoiceDTO[], [string, ChoiceDTO[]]>
): Record<string, PromptDTO[]> => {
  const eldsTournamentId = meta[0]
  const updatedPrompts = [...state[eldsTournamentId]]

  const updatedPromptIndex = updatedPrompts.findIndex(
    (prompt) => prompt.id === payload[0].promptId
  )
  updatedPrompts[updatedPromptIndex].choices = payload

  return {
    ...state,
    [eldsTournamentId]: updatedPrompts
  }
}

const appendOrReplaceByIdReducer = <T extends { eldsTournamentId: string }>(
  state: Record<string, T[]>,
  { payload }: PayloadAction<T>
): Record<string, T[]> => ({
    ...state,
    [payload.eldsTournamentId]: Object.values(
      keyById([...state[payload.eldsTournamentId], payload])
    )
  })

const appendOrReplaceByMetaIdReducer = <
  T extends { eldsTournamentId: string; id?: string },
>(
    state: Record<string, T[]>,
    { meta }: PayloadAction<null, T[]>
  ): Record<string, T[]> => {
  const [entity] = meta
  return {
    ...state,
    [entity.eldsTournamentId]: Object.values(
      keyById([...state[entity.eldsTournamentId], entity])
    )
  }
}

const omitByIdReducer = <T extends { eldsTournamentId: string; id?: string }>(
  state: Record<string, T[]>,
  { meta }: PayloadAction<null, T[]>
): Record<string, T[]> => {
  const [entity] = meta
  const entities: Record<string, T> = state[entity.eldsTournamentId].reduce(
    (acc: Record<string, T>, curr: T) => {
      acc[curr.id as string] = curr
      return acc
    },
    {}
  )

  return {
    ...state,
    [entity.eldsTournamentId]: Object.values(
      omit(entities, entity.id as string)
    )
  }
}

const keyByIdReducer = <T extends { id: string }>(
  state: Record<string, T>,
  { payload }: PayloadAction<T[]>
): Record<string, T> =>
    payload.reduce(
      (acc, curr) => {
        acc[curr.id] = curr
        return acc
      },
      { ...state }
    )

const leagueSlice = createSlice({
  name: 'league',
  initialState: {} as Record<string, LeagueDTO>,
  extraReducers: {
    [getLeagues.success.type]: (_, action: PayloadAction<LeagueDTO[]>) =>
      keyBy('eldsLeagueId')(action.payload),
    [updatePickemLeague.success.type]: (
      state,
      { payload, meta }: PayloadAction<LeagueDTO, [AdminLeagueDTO]>
    ) => {
      if (meta[0].activeEldsTournamentId) {
        return {
          ...state,
          [meta[0].eldsLeagueId]: payload
        }
      }
      else {
        const newState = { ...state }
        delete newState[meta[0].eldsLeagueId]
        return newState
      }
    },
    [updatePickemTournament.success.type]: (
      state,
      { meta }: PayloadAction<TournamentDTO, [TournamentDTO]>
    ) => ({
      ...state,
      [meta[0].leagueId]: {
        ...state?.[meta[0].leagueId],
        tournament: meta[0]
      }
    })
  } as Record<string, Reducer<Record<string, LeagueDTO>, AnyPayloadAction>>
})

const questionSlice = createSlice({
  name: 'question',
  initialState: {} as Record<string, QuestionDTO>,
  extraReducers: {
    [getPickemQuestions.success.type]: (
      _,
      action: PayloadAction<QuestionDTO[]>
    ) => keyById(action.payload),
    [insertPickemQuestions.success.type]: keyByIdReducer,
    [updateQuestionState.success.type]: (
      state: Record<string, QuestionDTO>,
      { payload }: PayloadAction<QuestionDTO, QuestionDTO>
    ) => ({
      ...state,
      [payload.id ?? '']: payload
    })
  } as Record<string, Reducer<Record<string, QuestionDTO>, AnyAction>>
})

const answerSlice = createSlice({
  name: 'answer',
  initialState: {} as Record<string, AnswerDTO>,
  extraReducers: {
    [getPickemAnswers.success.type]: (_, action: PayloadAction<AnswerDTO[]>) =>
      keyById(action.payload),
    [insertPickemAnswers.success.type]: keyByIdReducer,
    [updatePickemAnswerState.success.type]: (
      state: Record<string, AnswerDTO>,
      { payload }: PayloadAction<AnswerDTO, AnswerDTO>
    ) => ({
      ...state,
      [payload.id ?? '']: payload
    })
  } as Record<string, Reducer<Record<string, AnswerDTO>, AnyAction>>
})

const choiceTierSlice = createSlice({
  name: 'choiceTier',
  initialState: {} as Record<string, ChoiceTierDTO[]>,
  extraReducers: {
    [getChoiceTiers.success.type]: (
      state: Record<string, ChoiceTierDTO[]>,
      { payload }: PayloadAction<ChoiceTierDTO[], string>
    ): Record<string, ChoiceTierDTO[]> =>
      payload.reduce(
        (acc, curr) => {
          if (!acc[curr.promptId]) {
            acc[curr.promptId] = [curr]
          }
          else {
            acc[curr.promptId] = [...acc[curr.promptId], curr]
          }
          return acc
        },
        { ...state }
      ),
    [insertChoiceTiers.success.type]: (
      state: Record<string, ChoiceTierDTO[]>,
      { payload }: PayloadAction<ChoiceTierDTO[], [string, ChoiceTierDTO[]]>
    ): Record<string, ChoiceTierDTO[]> =>
      payload.reduce(
        (acc, curr) => {
          if (!acc[curr.promptId]) {
            acc[curr.promptId] = [curr]
          }
          else {
            acc[curr.promptId] = [...acc[curr.promptId], curr]
          }

          return acc
        },
        { ...state }
      ),
    [deleteChoiceTiers.success.type]: (
      state: Record<string, Record<string, ChoiceTierDTO[]>>,
      { meta }: PayloadAction<ChoiceTierDTO, string>
    ) => {
      const newState = { ...state }
      delete newState[meta]
      return newState
    }
  } as Record<string, Reducer<Record<string, ChoiceTierDTO[]>, AnyAction>>
})

const localizationSlice = createSlice({
  name: 'localization',
  initialState: {} as Record<string, LocalizationDTO[]>,
  extraReducers: {
    [getQuestionLocalizations.success.type]: byMetaIdLocalizationReducer,
    [getAnswerLocalizations.success.type]: byMetaIdLocalizationReducer,
    [insertPickemLocalizations.success.type]: appendByIdLocalizationReducer,
    [updatePickemLocalizations.success.type]: replaceByIdLocalizationReducer,
    [deletePickemLocalization.success.type]: (
      state: Record<string, LocalizationDTO[]>,
      action: PayloadAction<[], string[]>
    ) => {
      const updatedState = state[action.meta[0]].filter(
        (localizations) => localizations.locale !== action.meta[2]
      )
      return {
        ...state,
        [action.meta[0]]: updatedState
      }
    }
  } as Record<string, Reducer<Record<string, LocalizationDTO[]>, AnyAction>>
})

const tournamentCelebritySlice = createSlice({
  name: 'tournamentCelebrity',
  initialState: {} as Record<string, CelebrityDTO[]>,
  extraReducers: {
    [getCelebritiesForTournament.success.type]:
      byMetaEldsTournamentIdCelebritiesReducer
  } as Record<string, Reducer<Record<string, CelebrityDTO[]>, AnyAction>>
})

const pickBlockSlice = createSlice({
  name: 'pickBlock',
  initialState: {} as Record<string, PickBlockDTO[]>,
  extraReducers: {
    [getPickBlocks.success.type]: byMetaEldsTournamentIdPickBlocksReducer,
    [insertPickBlock.success.type]: appendOrReplaceByIdReducer,
    [deletePickBlock.success.type]: omitByIdReducer,
    [updatePickBlock.success.type]: appendOrReplaceByMetaIdReducer,
    [forcePickBlockState.success.type]: appendOrReplaceByMetaIdReducer
  } as Record<string, Reducer<Record<string, PickBlockDTO[]>, AnyAction>>
})

const promptSlice = createSlice({
  name: 'prompt',
  initialState: {} as Record<string, PromptDTO[]>,
  extraReducers: {
    [certifyPrompts.success.type]: (
      state: Record<string, PromptDTO[]>,
      { payload, meta }: PayloadAction<PromptDTO[], [string, PromptDTO[]]>
    ) => {
      const eldsTournamentId = meta[0]
      const updatedPromptMap = payload.reduce((acc, curr) => {
        acc[curr.id ?? ''] = curr
        return acc
      }, {})

      const updatedPrompts = state[eldsTournamentId].reduce((acc, curr) => {
        if (updatedPromptMap[curr.id ?? '']) {
          acc[curr.id ?? ''] = updatedPromptMap[curr.id ?? '']
        }
        else {
          acc[curr.id ?? ''] = curr
        }

        return acc
      }, {})

      return {
        ...state,
        [eldsTournamentId]: Object.values(updatedPrompts)
      }
    },
    [getPrompts.success.type]: byMetaEldsTournamentIdPromptReducer,
    [insertPrompts.success.type]: byMetaEldsTournamentIdPromptReducer,
    [insertChoices.success.type]: byChoicePromptReducer,
    [updateChoiceScore.success.type]: (
      state: Record<string, PromptDTO[]>,
      { payload, meta }: PayloadAction<ChoiceDTO, [string, ChoiceTierDTO]>
    ) => {
      const choiceId = meta[0]

      // get references to the associated prompt and the choice to update
      const prompt = Object.values(state)
        .flatMap((prompts) => prompts)
        .find((prompt) => prompt.id === payload.promptId)

      const promptIndex = state[prompt?.eldsTournamentId ?? ''].findIndex(
        (prompt) => prompt.id === payload.promptId
      )

      const choiceIndex
        = prompt?.choices?.findIndex((choice) => choice.id === choiceId) ?? -1

      // replace the old choice with the new one with a score
      const newPrompts = [...state[prompt?.eldsTournamentId ?? '']]
      if (!newPrompts[promptIndex].choices) {
        newPrompts[promptIndex].choices = []
      }
      ;(newPrompts[promptIndex].choices ?? [])[choiceIndex] = payload

      return {
        ...state,
        [prompt?.eldsTournamentId ?? '']: newPrompts
      }
    },
    [deleteChoices.success.type]: (
      state: Record<string, PromptDTO[]>,
      { meta }: PayloadAction<PromptDTO[], string>
    ) => {
      const promptId = meta[0]
      const prompt = Object.values(state)
        .flatMap((prompts) => prompts)
        .find((prompt) => prompt.id === promptId)
      const promptIndex = state[prompt?.eldsTournamentId ?? ''].findIndex(
        (prompt) => prompt.id === promptId
      )

      const newPrompts = [...state[prompt?.eldsTournamentId ?? '']]
      newPrompts[promptIndex].choices = []

      return {
        ...state,
        [prompt?.eldsTournamentId ?? '']: newPrompts
      }
    },
    [deletePrompt.success.type]: (
      state: Record<string, PromptDTO[]>,
      { payload, meta }: PayloadAction<PromptDTO, string>
    ) => {
      const promptId = meta[0]

      const promptIndex = state[payload?.eldsTournamentId ?? ''].findIndex(
        (prompt) => prompt.id === promptId
      )

      const newPrompts = [...state[payload?.eldsTournamentId ?? '']]
      newPrompts.splice(promptIndex, 1)

      return {
        ...state,
        [payload?.eldsTournamentId ?? '']: newPrompts
      }
    }
  } as Record<string, Reducer<Record<string, PromptDTO[]>, AnyAction>>
})

const rewardSlice = createSlice({
  name: 'reward',
  initialState: {} as Record<string, RewardCriteriaDTO[]>,
  extraReducers: {
    [getRewardCriteria.success.type]:
      byMetaEldsTournamentIdRewardCriteriaReducer,
    [insertRewardCriteria.success.type]: appendOrReplaceByIdReducer,
    [deleteRewardCriteria.success.type]: omitByIdReducer,
    [updateRewardCriteria.success.type]: appendOrReplaceByIdReducer
  } as Record<
    string,
    Reducer<Record<string, RewardCriteriaDTO[]>, AnyPayloadAction>
  >
})

const replaceByKey
  = <T, U extends keyof T = keyof T>(key: U) =>
    (
      state: Record<string, T> | undefined,
      { payload }: PayloadAction<T>
    ): Record<string, T> =>
      payload ? { ...state, [payload[key] as string]: payload } : { ...state }

const scoringConfiguration = createSlice({
  name: 'scoringConfiguration',
  initialState: {} as Record<string, ScoringConfigurationDTO>,
  extraReducers: {
    [getScoringConfigurations.success.type]: (
      _,
      action: PayloadAction<ScoringConfigurationDTO[]>
    ) => keyBy('id')(action.payload),
    [getScoringConfiguration.success.type]:
      replaceByKey<ScoringConfigurationDTO>('id'),
    [getScoringConfigurationBySection.success.type]:
      replaceByKey<ScoringConfigurationDTO>('id'),
    [createScoringConfiguration.success.type]:
      replaceByKey<ScoringConfigurationDTO>('id')
  }
})

const unscheduledItemSlice = createSlice({
  name: 'unscheduledItem',
  initialState: {} as Record<string, UnscheduledItemsDTO>,
  extraReducers: {
    [getUnscheduledItems.success.type]:
      byMetaEldsTournamentIdUnscheduledItemsReducer
  } as Record<string, Reducer<Record<string, UnscheduledItemsDTO>, AnyAction>>
})

const combinedReducer = combineReducers({
  isLoading: isLoadingReducer,
  answer: answerSlice.reducer,
  choiceTier: choiceTierSlice.reducer,
  league: leagueSlice.reducer,
  localization: localizationSlice.reducer,
  pickBlock: pickBlockSlice.reducer,
  prompt: promptSlice.reducer,
  question: questionSlice.reducer,
  reward: rewardSlice.reducer,
  scoringConfiguration: scoringConfiguration.reducer,
  tournamentCelebrity: tournamentCelebritySlice.reducer,
  unscheduledItem: unscheduledItemSlice.reducer
})

export const isPickemLeaguesLoading = (state: StateShape): boolean =>
  state.isLoading.isLeaguesLoading

export const isPickemUnscheduledItemsLoading = (state: StateShape): boolean =>
  state.isLoading.isUnscheduledItemsLoading

export const isPickemPickBlockOpLoading = (state: StateShape) =>
  state.isLoading.isPickBlockOpLoading

export const isPickemQuestionLocalizationsLoading = (state: StateShape) =>
  state.isLoading.isQuestionLocalizationsLoading
export const getPickemLeagues = (
  state: StateShape
): Record<string, LeagueDTO> => state.league

export const getPickemUnscheduledItems = (
  state: StateShape,
  eldsTournamentId: string
): UnscheduledItemsDTO =>
  getSafe(state.unscheduledItem, eldsTournamentId, {
    eldsMatchIds: [],
    eldsSectionIds: []
  })

export const getPickemPickBlocks = (
  state: StateShape,
  eldsTournamentId: string
): PickBlockDTO[] => state.pickBlock[eldsTournamentId]

export const getPickemCelebritiesByEldsTournamentId = (
  state: StateShape,
  eldsTournamentId: string
): CelebrityDTO[] => state.tournamentCelebrity[eldsTournamentId]

export const getPickemQuestion = (state: StateShape, questionId: string) =>
  state.question[questionId]

export const getPickemAnswer = (state: StateShape, answerId: string) =>
  state.answer[answerId]

export const getPickemRewardCriteriaByEldsTournamentId = (
  state: StateShape,
  eldsTournamentId: string
): RewardCriteriaDTO[] => state.reward[eldsTournamentId]

export const getQuestions = (state: StateShape): Record<string, QuestionDTO> =>
  state.question

export const getPickemLocalizations = (
  state: StateShape,
  id: string
): LocalizationDTO[] => state.localization[id]

export const getAnswers = (state: StateShape): Record<string, AnswerDTO> =>
  state.answer

export const getPromptsByEldsTournamentId = (
  state: StateShape,
  eldsTournamentId: string
): PromptDTO[] => state.prompt[eldsTournamentId]

export const getAllChoiceTiers = (state: StateShape) => state.choiceTier

export const getPickemTournamentByEldsTournamentId = (
  state: StateShape,
  eldsTournamentId: string
): TournamentDTO | undefined => {
  const index = Object.values(state.league).findIndex(
    (league) => league.tournament?.eldsTournamentId === eldsTournamentId
  )

  return Object.values(state.league)[index]?.tournament
}

export const getPickemScoringConfigurations = (state: StateShape) =>
  state.scoringConfiguration

export const getPickemScoringConfiguration = (state: StateShape, id: string) =>
  state.scoringConfiguration[id]

export default combinedReducer
