import { Config, fetch, urlBuilder } from '../../commons'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'
import {
  AdminLeagueDTO,
  AnswerDTO,
  AnswersDTO,
  CelebritiesDTO,
  CelebrityDTO,
  CelebrityImportBatchDTO,
  CelebrityImportResultsDTO,
  ChoiceDTO,
  ChoiceTierDTO,
  LeagueDTO,
  LeaguesDTO,
  PickBlockState,
  PickBlockDTO,
  PickBlocksDTO,
  PromptDTO,
  QuestionDTO,
  QuestionType,
  RewardCriteriaDTO,
  ScoringConfigurations,
  ScoringConfigurationCreate,
  ScoringConfigurationDTO,
  UnscheduledItemsDTO,
  LocalizationDTO as pickemLocalizationDTO,
  TournamentDTO
} from './PickemApi.type'

export const PickemApiEnvSessionStorageKey = 'pickemApiEnv'
export const PickemLifecycleApiEnvSessionStorageKey = 'pickemLifecycleApiEnv'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.Pickem,
  Config.getEnv(PickemApiEnvSessionStorageKey)
)
const pickemBaseUrl = `${apiBaseUrl}/pickemv2/api`
const pickemAdminBaseUrl = `${apiBaseUrl}/pickemv2admin`

const lifecycleApiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.PickemLifecycle,
  Config.getEnv(PickemLifecycleApiEnvSessionStorageKey)
)
const lifecycleBaseUrl = `${lifecycleApiBaseUrl}/pickem/lifecycle`

const baseLifecycleUrl = Config.getKeyByEnv({
  local: lifecycleBaseUrl,
  dev: lifecycleBaseUrl,
  test: lifecycleBaseUrl,
  stage: lifecycleBaseUrl,
  prod: lifecycleBaseUrl
})

const baseUrl = Config.getKeyByEnv({
  local: pickemBaseUrl,
  dev: pickemBaseUrl,
  test: pickemBaseUrl,
  stage: pickemBaseUrl,
  prod: pickemBaseUrl
})

const baseAdminUrl = Config.getKeyByEnv({
  local: pickemAdminBaseUrl,
  dev: pickemAdminBaseUrl,
  test: pickemAdminBaseUrl,
  stage: pickemAdminBaseUrl,
  prod: pickemAdminBaseUrl
})

const pickemAdminUrl = urlBuilder({
  local: `${baseAdminUrl}/v1/admin`,
  dev: `${baseAdminUrl}/v1/admin`,
  test: `${baseAdminUrl}/v1/admin`,
  prod: `${baseAdminUrl}/v1/admin`
})

const celebrityAdminUrl = urlBuilder({
  local: `${baseAdminUrl}/v1/celebrity-admin`,
  dev: `${baseAdminUrl}/v1/celebrity-admin`,
  test: `${baseAdminUrl}/v1/celebrity-admin`,
  prod: `${baseAdminUrl}/v1/celebrity-admin`
})

const crystalBallAdminUrl = urlBuilder({
  local: `${baseAdminUrl}/v1/crystal-ball-admin`,
  dev: `${baseAdminUrl}/v1/crystal-ball-admin`,
  test: `${baseAdminUrl}/v1/crystal-ball-admin`,
  prod: `${baseAdminUrl}/v1/crystal-ball-admin`
})

const crystalBallUrl = urlBuilder({
  local: `${baseUrl}/v1/crystal-ball-picks`,
  dev: `${baseUrl}/v1/crystal-ball-picks`,
  test: `${baseUrl}/v1/crystal-ball-picks`,
  prod: `${baseUrl}/v1/crystal-ball-picks`
})

const pickBlockAdminUrl = urlBuilder({
  local: `${baseAdminUrl}/v1/pick-blocks-admin`,
  dev: `${baseAdminUrl}/v1/pick-blocks-admin`,
  test: `${baseAdminUrl}/v1/pick-blocks-admin`,
  prod: `${baseAdminUrl}/v1/pick-blocks-admin`
})

const rewardsAdminUrl = urlBuilder({
  local: `${baseAdminUrl}/v1/rewards-admin`,
  dev: `${baseAdminUrl}/v1/rewards-admin`,
  test: `${baseAdminUrl}/v1/rewards-admin`,
  prod: `${baseAdminUrl}/v1/rewards-admin`
})

const scoringConfigurationAdminUrl = urlBuilder({
  local: `${baseAdminUrl}/v1/scoring-config-admin`,
  dev: `${baseAdminUrl}/v1/scoring-config-admin`,
  test: `${baseAdminUrl}/v1/scoring-config-admin`,
  prod: `${baseAdminUrl}/v1/scoring-config-admin`
})

const lifecycleTestUrl = urlBuilder({
  local: `${baseLifecycleUrl}/load-test`,
  dev: `${baseLifecycleUrl}/load-test`,
  test: `${baseLifecycleUrl}/load-test`,
  prod: `${baseLifecycleUrl}/load-test`
})

class PickemApi {
  static async getOptions (method = 'POST'): Promise<RequestInit> {
    return {
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }

  async fetchLeagues (): Promise<LeagueDTO[]> {
    const json: LeaguesDTO = await fetch(
      pickemAdminUrl('/leagues'),
      await PickemApi.getOptions('GET')
    )
    return json.leagues
  }

  async updateLeague (league: AdminLeagueDTO): Promise<LeagueDTO> {
    const opts = await PickemApi.getOptions('PUT')
    return fetch(pickemAdminUrl(`/leagues/${league.eldsLeagueId}`), {
      ...opts,
      body: JSON.stringify(league)
    })
  }

  async updateTournament (tournament: TournamentDTO): Promise<void> {
    const opts = await PickemApi.getOptions('PUT')
    return fetch(pickemAdminUrl(`/tournament/${tournament.eldsTournamentId}`), {
      ...opts,
      body: JSON.stringify(tournament)
    })
  }

  async fetchPickBlocks (eldsTournamentId: string): Promise<PickBlockDTO[]> {
    const json: PickBlocksDTO = await fetch(
      pickBlockAdminUrl(`/tournament/${eldsTournamentId}`),
      await PickemApi.getOptions('GET')
    )
    return json.pickBlocks
  }

  async fetchUnscheduled (
    eldsTournamentId: string
  ): Promise<UnscheduledItemsDTO> {
    return await fetch(
      pickBlockAdminUrl(`/tournament/${eldsTournamentId}/unscheduled`),
      await PickemApi.getOptions('GET')
    )
  }

  // Celebrity Admin Endpoints
  async fetchCelebritiesForTournament (
    eldsTournamentId: string
  ): Promise<CelebrityDTO[]> {
    const json: CelebritiesDTO = await fetch(
      celebrityAdminUrl(`/tournament/${eldsTournamentId}`),
      await PickemApi.getOptions('GET')
    )

    return json.celebrities
  }

  async validateCelebrityImportForTournament (
    eldsTournamentId: string,
    celebrities: CelebrityImportBatchDTO
  ): Promise<CelebrityImportResultsDTO> {
    const opts = await PickemApi.getOptions('PUT')
    return fetch(celebrityAdminUrl(`/batch/${eldsTournamentId}/validate`), {
      ...opts,
      body: JSON.stringify(celebrities)
    })
  }

  async importCelebritiesForTournament (
    eldsTournamentId: string,
    celebrities: CelebrityImportBatchDTO
  ): Promise<CelebrityImportResultsDTO> {
    const opts = await PickemApi.getOptions('PUT')
    return fetch(celebrityAdminUrl(`/batch/${eldsTournamentId}`), {
      ...opts,
      body: JSON.stringify(celebrities)
    })
  }

  // Crystal Ball Endpoints
  async deletePrompt (promptId: string): Promise<PromptDTO> {
    return await fetch(
      crystalBallAdminUrl(`/v2/prompts/${promptId}`),
      await PickemApi.getOptions('DELETE')
    )
  }

  async deleteChoices (promptId: string): Promise<ChoiceDTO[]> {
    const json = await fetch(
      crystalBallAdminUrl(`/v2/prompts/${promptId}/choices`),
      await PickemApi.getOptions('DELETE')
    )

    return json.choices
  }

  async deleteChoiceTiers (promptId: string): Promise<ChoiceTierDTO[]> {
    const json = await fetch(
      crystalBallAdminUrl(`/v2/prompts/${promptId}/choice-tiers`),
      await PickemApi.getOptions('DELETE')
    )

    return json.choiceTiers
  }

  async getAnswers (
    questionType: QuestionType | 'ALL' = 'ALL'
  ): Promise<AnswerDTO[]> {
    const url
      = questionType === 'ALL'
        ? '/v2/answers'
        : `/v2/answers?answerType=${questionType}`
    const json = await fetch(
      crystalBallAdminUrl(url),
      await PickemApi.getOptions('GET')
    )

    return json.answers
  }

  async getPrompts (eldsTournamentId: string): Promise<PromptDTO[]> {
    const json = await fetch(
      crystalBallUrl(`/v2/tournament/${eldsTournamentId}/prompts`),
      await PickemApi.getOptions('GET')
    )

    return json.prompts
  }

  async getChoiceTiers (eldsTournamentId: string): Promise<ChoiceTierDTO[]> {
    const json = await fetch(
      crystalBallUrl(`/v2/${eldsTournamentId}/choiceTiers`),
      await PickemApi.getOptions('GET')
    )

    return json.choiceTiers
  }

  async getQuestions (
    questionType: QuestionType | 'ALL' = 'ALL'
  ): Promise<QuestionDTO[]> {
    const url
      = questionType === 'ALL'
        ? '/v2/questions'
        : `/v2/questions?questionType=${questionType}`
    const json = await fetch(
      crystalBallAdminUrl(url),
      await PickemApi.getOptions('GET')
    )

    return json.questions
  }

  async insertAnswers (answers: AnswerDTO[]): Promise<AnswerDTO[]> {
    const opts = await PickemApi.getOptions('PUT')
    const json: AnswersDTO = await fetch(crystalBallAdminUrl('/v2/answers'), {
      ...opts,
      body: JSON.stringify({ answers })
    })

    return json.answers
  }

  async insertChoices (
    eldsTournamentId: string,
    choices: ChoiceDTO[]
  ): Promise<ChoiceDTO[]> {
    const opts = await PickemApi.getOptions('PUT')
    const json = await fetch(crystalBallAdminUrl('/v2/choices'), {
      ...opts,
      body: JSON.stringify({ choices })
    })

    return json.choices
  }

  async insertChoiceTiers (
    eldsTournamentId: string,
    choiceTiers: ChoiceTierDTO[]
  ): Promise<ChoiceTierDTO[]> {
    const json = await fetch(crystalBallAdminUrl(`/v2/choicetiers`), {
      ...await PickemApi.getOptions('PUT'),
      body: JSON.stringify({ eldsTournamentId, choiceTiers })
    })

    return json.choiceTiers
  }

  async insertPrompts (
    eldsTournamentId: string,
    prompts: PromptDTO[]
  ): Promise<PromptDTO[]> {
    const json = await fetch(crystalBallAdminUrl(`/v2/prompts`), {
      ...await PickemApi.getOptions('PUT'),
      body: JSON.stringify({ eldsTournamentId, prompts })
    })

    return json.prompts
  }

  async insertQuestions (questions: QuestionDTO[]): Promise<QuestionDTO[]> {
    const opts = await PickemApi.getOptions('PUT')
    const json = await fetch(crystalBallAdminUrl('/v2/questions'), {
      ...opts,
      body: JSON.stringify({ questions })
    })

    return json.questions
  }

  async updateAnswerState (answer: AnswerDTO): Promise<AnswerDTO> {
    const opts = await PickemApi.getOptions('PUT')
    return fetch(crystalBallAdminUrl(`/v2/answers/${answer.id}`), {
      ...opts,
      body: JSON.stringify(answer)
    })
  }

  async updateChoiceScore (
    choiceId: string,
    choiceTier?: ChoiceTierDTO
  ): Promise<ChoiceDTO> {
    const opts = await PickemApi.getOptions('PUT')
    if (choiceTier) {
      opts.body = JSON.stringify(choiceTier)
    }
    const json = await fetch(
      crystalBallAdminUrl(`/v2/choices/${choiceId}`),
      opts
    )

    return json.choices[0]
  }

  async certifyPrompts (
    eldsTournamentId: string,
    prompts: PromptDTO[]
  ): Promise<PromptDTO[]> {
    const opts = await PickemApi.getOptions('PUT')
    const json = await fetch(crystalBallAdminUrl('/v2/certifyPrompts'), {
      ...opts,
      body: JSON.stringify({ prompts, eldsTournamentId })
    })

    return json.prompts
  }

  async updateQuestionState (question: QuestionDTO): Promise<QuestionDTO> {
    const opts = await PickemApi.getOptions('PUT')
    return fetch(crystalBallAdminUrl(`/v2/questions/${question.id}`), {
      ...opts,
      body: JSON.stringify(question)
    })
  }

  // Localization Endpoints
  async getQuestionLocalizations (
    questionId: string
  ): Promise<pickemLocalizationDTO[]> {
    const json = await fetch(
      crystalBallAdminUrl(`/v2/questions/${questionId}/localizations`),
      await PickemApi.getOptions('GET')
    )

    return json.localizations
  }

  async getAnswerLocalizations (
    answerId: string
  ): Promise<pickemLocalizationDTO[]> {
    const json = await fetch(
      crystalBallAdminUrl(`/v2/answers/${answerId}/localizations`),
      await PickemApi.getOptions('GET')
    )

    return json.localizations
  }

  async insertLocalizations (
    id: string,
    localizations: pickemLocalizationDTO[]
  ): Promise<pickemLocalizationDTO[]> {
    const opts = await PickemApi.getOptions('POST')
    const json: pickemLocalizationDTO[] = await fetch(
      crystalBallAdminUrl('/v2/localizations'),
      {
        ...opts,
        body: JSON.stringify({ localizations })
      }
    )

    return json
  }

  async updateLocalizations (
    id: string,
    localizations: pickemLocalizationDTO[]
  ): Promise<pickemLocalizationDTO[]> {
    const opts = await PickemApi.getOptions('PUT')
    const json: pickemLocalizationDTO[] = await fetch(
      crystalBallAdminUrl('/v2/localizations'),
      {
        ...opts,
        body: JSON.stringify({ localizations })
      }
    )

    return json
  }
  async deleteLocalization (
    id: string,
    key: string,
    locale: string
  ): Promise<void> {
    const opts = await PickemApi.getOptions('DELETE')
    return fetch(crystalBallAdminUrl(`/v2/localization/${key}/${locale}`), opts)
  }

  // Pick Block Admin Endpoints
  async insertPickBlock (pickBlock: PickBlockDTO): Promise<PickBlockDTO> {
    const opts = await PickemApi.getOptions()
    return fetch(pickBlockAdminUrl(''), {
      ...opts,
      body: JSON.stringify(pickBlock)
    })
  }

  async updatePickBlock (pickBlock: PickBlockDTO): Promise<void> {
    const opts = await PickemApi.getOptions('PUT')
    return fetch(pickBlockAdminUrl(`/${pickBlock.id}`), {
      ...opts,
      body: JSON.stringify(pickBlock)
    })
  }

  async deletePickBlock (pickBlock: PickBlockDTO): Promise<void> {
    const opts = await PickemApi.getOptions('DELETE')
    return fetch(pickBlockAdminUrl(`/${pickBlock.id}`), opts)
  }

  // Rewards Admin Endpoints
  async fetchRewardCriteriaForTournament (
    eldsTournamentId: string
  ): Promise<RewardCriteriaDTO[]> {
    const json = await fetch(
      rewardsAdminUrl(`/tournament/${eldsTournamentId}`),
      await PickemApi.getOptions('GET')
    )

    return json
  }

  async insertRewardCriteria (
    criteria: RewardCriteriaDTO
  ): Promise<RewardCriteriaDTO> {
    const opts = await PickemApi.getOptions()
    return fetch(rewardsAdminUrl(''), {
      ...opts,
      body: JSON.stringify(criteria)
    })
  }

  async updateRewardCriteria (
    criteria: RewardCriteriaDTO
  ): Promise<RewardCriteriaDTO> {
    const opts = await PickemApi.getOptions('PUT')
    return fetch(rewardsAdminUrl(`/${criteria.id}`), {
      ...opts,
      body: JSON.stringify(criteria)
    })
  }

  async deleteRewardCriteria (criteria: RewardCriteriaDTO): Promise<void> {
    const opts = await PickemApi.getOptions('DELETE')
    return fetch(rewardsAdminUrl(`/${criteria.id}`), opts)
  }

  // Scoring Configuration Endpoints

  async getScoringConfigurations (): Promise<ScoringConfigurationDTO[]> {
    const json: ScoringConfigurations = await fetch(
      scoringConfigurationAdminUrl(''),
      await PickemApi.getOptions('GET')
    )

    return json.collection
  }

  async getScoringConfiguration (id: string): Promise<ScoringConfigurationDTO> {
    const json: ScoringConfigurationDTO = await fetch(
      scoringConfigurationAdminUrl(`/${id}`),
      await PickemApi.getOptions('GET')
    )

    return json
  }

  async getScoringConfigurationBySection (
    eldsSectionId: string
  ): Promise<ScoringConfigurationDTO | undefined> {
    const json: { scoringConfig: ScoringConfigurationDTO } | undefined
      = await fetch(
        scoringConfigurationAdminUrl(`/section/${eldsSectionId}`),
        await PickemApi.getOptions('GET'),
        false,
        true
      )

    return json?.scoringConfig
  }

  async createScoringConfiguration (
    config: ScoringConfigurationCreate
  ): Promise<ScoringConfigurationDTO> {
    const opts = await PickemApi.getOptions('POST')
    const json: ScoringConfigurationDTO = await fetch(
      scoringConfigurationAdminUrl(''),
      {
        ...opts,
        body: JSON.stringify({ ...config })
      }
    )

    return json
  }

  async updateSectionScoringConfiguration (
    eldsSectionId: string,
    scoringConfigId: string,
    config: ScoringConfigurationDTO
  ): Promise<ScoringConfigurationDTO> {
    const opts = await PickemApi.getOptions('PUT')
    const json: ScoringConfigurationDTO = await fetch(
      scoringConfigurationAdminUrl(
        `/section/${eldsSectionId}/assign/${scoringConfigId}`
      ),
      {
        ...opts,
        body: JSON.stringify({ eldsSectionId, scoringConfig: config })
      }
    )

    return json
  }

  async deleteSectionScoringConfiguration (
    eldsSectionsId: string
  ): Promise<void> {
    return await fetch(
      scoringConfigurationAdminUrl(`/section/${eldsSectionsId}`),
      await PickemApi.getOptions('DELETE')
    )
  }

  async forcePickBlockState (
    pickBlock: PickBlockDTO,
    state: PickBlockState
  ): Promise<PickBlockDTO> {
    await fetch(
      lifecycleTestUrl(`/pickblock/${pickBlock.id}/force/${state}`),
      await PickemApi.getOptions('PUT')
    )

    return pickBlock
  }
}

export default new PickemApi()
